import React from 'react';
import { Layout, Typography } from "antd";
import { graphql } from "gatsby";

import "katex/dist/katex.min.css";

const { Content } = Layout;
const { Title } = Typography;

const Template = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      <Content>
        <Title>{ frontmatter.title }</Title>
        <Title level={2}>{ frontmatter.date }</Title>
        <Content dangerouslySetInnerHTML={{ __html: html }} />
      </Content>
    </Layout>
  )
};

export default Template;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        slug
        title
      }
    }
  }
`;
